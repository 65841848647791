"use client";

import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import styles from "./HomeHero.module.scss";
import Image from "next/image";
import Play from "@/components/icons/Play";
import If from "@/components/If";

import Facebook from "@/components/icons/Facebook";
import Instagram from "@/components/icons/Instagram";
import LinkedIn from "@/components/icons/LinkedIn";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const PInP = ({ show = false }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  return (
    <button
      className={`pip-video fixed rounded overflow-hidden z-[3000] right-4 hidden md:block transition-all duration-300 bg-black ${
        show ? "show pointer-events-auto" : "hide pointer-events-none"
      } ${
        isFullScreen
          ? "top-[50%] -translate-y-[50%] right-[50%] translate-x-[50%] w-[80dvw] h-auto aspect-video"
          : "top-[60%] w-[346px] h-[196px]"
      }`}
      onClick={() => {
        setIsFullScreen(!isFullScreen);
      }}
    >
      <If condition={!isFullScreen}>
        <iframe
          src="https://player.vimeo.com/video/845937637?h=9725f49d2e&app_id=58479&title=0&byline=0&portrait=0&color=ffae39&controls=1&background=1&autoplay=1"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          loading="lazy"
          title={"Illuminus Brand Video"}
          className="object-cover w-[150%] h-[150%] relative top-[-25%] left-[-25%] pointer-events-none"
        />
        {/* <video
          src="/video/illuminus_sq.mp4"
          title="Illuminus Brand Video"
          className="object-cover w-full h-full"
          autoPlay
          playsInline
          muted
          preload="none"
          loop
        /> */}
        <div className="absolute bottom-3 left-3 w-[44px]">
          <Play width={44} />
        </div>
      </If>
      <If condition={isFullScreen}>
        <iframe
          src="https://player.vimeo.com/video/845937637?h=9725f49d2e&app_id=58479&title=0&byline=0&portrait=0&color=ffae39&controls=1&autoplay=1&loop=1"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          loading="lazy"
          title={"Illuminus Brand Video"}
          className="object-cover w-full h-full"
        />
        {/* <video
          src="/video/illuminus_sq.mp4"
          title="Illuminus Brand Video"
          className="object-cover w-full h-full"
          controls
          muted={false}
          autoPlay
          preload="none"
          loop
        /> */}
        <button
          className="absolute top-3 right-3 text-orange border-2 border-orange rounded-full p-2 font-bold"
          onClick={() => setIsFullScreen(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </If>
    </button>
  );
};

/**
 *
 * HomeHero
 *
 */
const HomeHero = ({ ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const [progress, setProgress] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState({
    image1: false,
    image2: false,
    image3: false
  });

  const container = useRef(null);
  const image1 = useRef(null);
  const image2 = useRef(null);
  const image3 = useRef(null);
  const text1 = useRef(null);
  const text2 = useRef(null);
  const text3 = useRef(null);

  // Handle image loading
  useEffect(() => {
    if (imagesLoaded.image1 && imagesLoaded.image2 && imagesLoaded.image3) {
      setLoaded(true);
    }
  }, [imagesLoaded]);

  // Set initial visibility state
  useEffect(() => {
    if (loaded) {
      // Initialize elements with correct visibility
      gsap.set(image1.current, { opacity: 1 });
      gsap.set(image2.current, { opacity: 0 });
      gsap.set(image3.current, { opacity: 0 });
      gsap.set(text1.current, { opacity: 1 });
      gsap.set(text2.current, { opacity: 0 });
      gsap.set(text3.current, { opacity: 0 });
    }
  }, [loaded]);

  useGSAP(
    () => {
      if (!gsap || !loaded) {
        return;
      }

      var tl = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          pin: true, // pin the trigger element while active
          start: "top top", // when the top of the trigger hits the top of the viewport
          end: "bottom bottom", // end after scrolling 500px beyond the start
          scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
          onEnterBack: () => {
            setShowVideo(true);
          },
          onEnter: () => {
            setShowVideo(true);
          },
          onLeave: () => {
            setShowVideo(false);
          },
          onUpdate: (self) => {
            setProgress(Number(self.progress?.toFixed(3)) || 0);
          },
        },
      });

      // First image and text fade out as we scroll
      tl.to(
        [image1.current, text1.current],
        {
          opacity: 0,
          duration: 1,
          ease: "power1.inOut",
        },
        0
      );
      
      // Second image and text fade in
      tl.to(
        [image2.current, text2.current],
        {
          opacity: 1,
          duration: 1,
          ease: "power1.inOut",
        },
        0
      );
      
      // Third image and text fade in later
      tl.to(
        [image3.current, text3.current],
        {
          opacity: 1,
          duration: 1,
          delay: 1,
          ease: "power1.inOut",
        },
        1
      );
    },
    {
      dependencies: [
        loaded,
        container.current,
        image1.current,
        image2.current,
        image3.current,
        text1.current,
        text2.current,
        text3.current,
      ],
    }
  );
  return (
    <>
      <header
        className={`${styles["home-hero"]} h-[300dvh] overflow-hidden relative`}
        {...props}
        ref={container}
      >
        <div
          ref={image1}
          className="z-[1] absolute top-0 left-0 w-full h-screen"
        >
          <Image
            src="/fpos/home-hero-1.jpg"
            alt="Home Hero"
            className="object-cover pointer-events-none"
            fill
            priority
            onLoad={() => setImagesLoaded(prev => ({ ...prev, image1: true }))}
          />
          <div
            className="left-0 top-[50%] -translate-y-[50%] text-white absolute px-gutter"
            ref={text1}
          >
            <h1 className="text-section-title uppercase font-bold">
              WELCOME TO ILLUMINUS
            </h1>
            <div className="flex gap-4 items-center">
              <a
                href={"https://www.facebook.com/liveilluminus"}
                className="text-sm text-white hover:text-orange transition-all duration-300"
                target="_blank"
                rel="noopener"
              >
                <span className="sr-only">Facebook</span>
                <Facebook width="25" />
              </a>
              <a
                href={"https://www.instagram.com/liveilluminus/"}
                className="text-sm text-white hover:text-orange transition-all duration-300"
                target="_blank"
                rel="noopener"
              >
                <span className="sr-only">Instagram</span>
                <Instagram width="25" />
              </a>

              <a
                href={"http://www.linkedin.com/company/liveilluminus"}
                className="text-sm text-white hover:text-orange transition-all duration-300"
                target="_blank"
                rel="noopener"
              >
                <span className="sr-only">LinkedIn</span>
                <LinkedIn width="25" />
              </a>
            </div>
          </div>
        </div>
        <div
          className="absolute top-0 left-0 w-full h-screen z-[2] pointer-events-none"
          ref={image2}
        >
          <Image
            src="/fpos/illuminus-home-2.jpg"
            alt="Home Hero"
            className="object-cover"
            fill
            priority
            onLoad={() => setImagesLoaded(prev => ({ ...prev, image2: true }))}
          />
          <div className="absolute left-0 top-[50%] -translate-y-[50%] text-white px-gutter max-w-full w-[1200px]" ref={text2}>
            <p className=" text-large">
              Discover the future of senior living, where innovation meets
              comfort and care.
            </p>
            <p className=" text-large">
              Experience unparalleled senior lifestyle options designed to
              enrich the lives of older adults.
            </p>
          </div>
        </div>
        <div
          className="absolute top-0 left-0 w-full h-screen z-[3] pointer-events-none"
          ref={image3}
        >
          <Image
            src="/fpos/home-hero-3.jpg"
            alt="Home Hero"
            className="object-cover"
            fill
            priority
            onLoad={() => setImagesLoaded(prev => ({ ...prev, image3: true }))}
          />
          <div className="absolute left-0 top-[50%] -translate-y-[50%] text-white px-gutter" ref={text3}>
            <p className=" text-large">
              Welcome to Illuminus – redefining senior living excellence.
            </p>
          </div>
        </div>
      </header>

      <PInP show={showVideo} />
      <div
        className={`w-full h-[15px] bg-orange fixed bottom-0 left-0 z-[50] ${
          showVideo ? "opacity-100" : "opacity-0"
        }`}
        style={{ transform: `scaleX(${progress})`, transformOrigin: "left" }}
      ></div>
    </>
  );
};

export default HomeHero;
